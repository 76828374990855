<template>
  <v-app>
    <v-banner
        single-line
        height="400"
        class="children-banner"
    >
      <v-carousel height="100%"
                  class="carousel-new carousel-banner"
                  hide-delimiter-background
                  hide-delimiters
                  :show-arrows="false">
        <v-carousel-item v-for="(banner,index) in bannerList" :key="index">
          <v-img
              :src="banner.img"
              height="100%"
          >
            <template v-slot:placeholder>
              <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
              >
                <v-progress-circular
                    indeterminate
                    size="80"
                    width="8"
                    color="grey lighten-3"
                ></v-progress-circular>
              </v-row>
            </template>
            <v-container color="transparent" class="pa-0 max-width-1200 height-per-100 d-flex align-center justify-center">
              <v-card color="transparent" class="ml-0" flat>
                <v-card-title class="pa-0 font-size-banner-title family-Bold justify-center" :style="'color: ' + banner.titleColor + '!important;'">
                  <div>
                    <div v-for="(title,tipIndex) in banner.title" :key="tipIndex" class="pt-2" style="font-weight: 500">{{title}}</div>
                  </div>
                </v-card-title>
                <v-card-title class="pa-0 font-size-banner-tip mt-8" :style="'color: ' + banner.titleColor + '!important;'">
                  <div>
                    <div v-for="(tip,tipIndex) in banner.tip" :key="tipIndex" class="mb-3">{{tip}}</div>
                  </div>
                </v-card-title>
                <v-card-actions class="pa-0 mt-10 justify-center" v-if="banner.btn">
                  <v-btn
                      rounded
                      color="#0568FD"
                      width="160"
                      height="50"
                      class="font-size-20"
                      @click.stop="handleBannerBtn(banner.router)"
                  >
                    <span class="color-FFF">{{ banner.btn }}</span>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-container>
          </v-img>
        </v-carousel-item>
      </v-carousel>
    </v-banner>
    <!-- 产品特性 -->
    <div class="pb-5 " style="margin:0 auto;">
      <v-container color="transparent" class="pa-0 ">
        <div class="public-title">
          <p>PRODUCT CHARACTERISTICS</p>
          <div>
            <span>产品特性</span>
          </div>
        </div>
        <!-- <div style="text-align:center;"> -->
          <div class="d-flex justify-center" style="tsxt-align:center;">
            <v-card flat v-for="(info,index) in productChars" :key="index">
              <div class="d-flex justify-center">
                <img  style="max-width:120px;max-height:120px;" :src="info.img"/>
              </div>
              <div style="width:500px;">
                <v-card-title class="justify-center pa-0 mt-5">{{info.title}}</v-card-title>
                <v-card-text class="d-flex justify-center mt-4">
                  <div style="width: 225px;" class="text-align-center font-size-14">{{info.tip}}</div>
                </v-card-text>
              </div>
            </v-card>
          </div>
        <!-- </div> -->
      </v-container>
    </div>
    <!-- 应用场景 -->
    <div class="d-flex justify-center width-per-100" style="padding-bottom: 70px;background-color: #F5F9FD;">
      <v-card flat class="max-width-1200 width-per-100" color="transparent">
        <div class="public-title">
          <p>APPLICATION SCENARIO</p>
          <div>
            <span>应用场景</span>
          </div>
        </div>
        <v-tabs v-model="tab1" centered background-color="transparent" class="g-tabs font-size-20">
          <v-tab v-for="(v, k) in appSences" :key="k">
            <b>{{ v.tabTitle }}</b>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab1" class="mt-12" style="background-color: transparent;" >
          <v-tab-item v-for="(v, k) in appSences" :key="k"
                      :transition="false">
            <div class="d-flex justify-center" style=" width:900px; margin:0 auto;">
              <transition name="slide-fade" appear>
                <v-img style="background-color: transparent"
                       class="position-r"
                       contain
                       max-width="400" max-height="260" min-height="260"
                       v-show="tab1 === k"
                       :src="v.img">
                  <template v-slot:placeholder>
                    <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                      <v-progress-circular
                          indeterminate
                          size="80"
                          width="8"
                          color="grey lighten-3"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                  <div class="gifStyle" v-if="v.imgChildren"><img :src="v.imgChildren"/></div>
                </v-img>
              </transition>
              <transition name="slide-fade-reverse" appear>
                <div style="margin-left: 80px;width: 385px;"
                     class="d-flex align-center"
                     v-show="tab1 === k">
                  <div>
                    <v-card-title class="pa-0 font-size-24 font-weight-bold white-space-pre-wrap" style="line-height: 1.2">{{v.childrenTitle}}</v-card-title>
                    <v-card-text class="pa-0 mt-4 font-size-16 color-666">
                      <div class="width-per-100 white-space-pre-wrap">{{v.childrenTip}}</div>
                    </v-card-text>
                  </div>
                </div>
              </transition>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
    <!-- 经典案例 -->
    <!--<div class="d-flex justify-center home-case">-->
    <!--  <v-card class="max-width-1200" flat>-->
    <!--    <div class="public-title" style="margin-top: 60px;">-->
    <!--      <p>CASE STUDY</p>-->
    <!--      <div>-->
    <!--        <span>经典案例</span>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="new-tabs-items-two mt-2 transparent" >-->
    <!--      <transition name="fade">-->
    <!--        <div class="width-per-100 d-flex justify-center px-2">-->
    <!--          <v-card flat-->
    <!--                  v-for="(info,infoIndex) in caseList" :key="infoIndex"-->
    <!--                  :class="['item-card',(caseList.length-1) != infoIndex?'mr-10':'']"-->
    <!--                  width="240"-->
    <!--                  height="410"-->
    <!--                  @click="goCaseDetail(info)"-->
    <!--          >-->
    <!--            <v-img-->
    <!--                max-height="340"-->
    <!--                class="tran-sec"-->
    <!--                :src="info.coverImg"-->
    <!--                :aspect-ratio="9/16"-->
    <!--            >-->
    <!--            </v-img>-->
    <!--            <div class="text-box">-->
    <!--              <v-card-text class="title" v-text="info.itemTitle"></v-card-text>-->
    <!--            </div>-->
    <!--          </v-card>-->
    <!--        </div>-->
    <!--      </transition>-->
    <!--    </div>-->
    <!--  </v-card>-->
    <!--</div>-->
  </v-app>
</template>

<script>
import textData from '@/static/textData.js';
import qs from 'qs'

export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      data1: textData.WmyHome,
      tab1: null,
      tab2: null,
      productChars: [
        {
          img: require("../../../public/img/icons/3DSceneEditor/productChar01.png"),
          title: "图形化界面",
          tip: "万擎云提供图形化的编辑页面，让3D场景的制作更简单"
        },
        {
          img: require("../../../public/img/icons/3DSceneEditor/productChar02.png"),
          title: "低代码脚本",
          tip: "详尽的生命周期函数，简单易用的预制接口，少量代码即可写出优雅脚本"
        },
        {
          img: require("../../../public/img/icons/3DSceneEditor/productChar03.png"),
          title: "跨平台 多终端",
          tip: "支持安卓，IOS，H5，小程序等多渠道同时发布，适用更多场景"
        }
      ],
      appSences: [
        {
          tabTitle: '3D场景搭建',
          img: "https://h5.ophyer.cn/official_website/other/develop5-1new.png",
          childrenTitle: '可视化操作，场景搭建更简单',
          childrenTip: "平台预设的3D模型，搭配用户上传的贴图可以快速搭建业务场景所需的3D场景"
        },
        {
          tabTitle: '动画制作',
          img: "https://h5.ophyer.cn/official_website/other/develop5-2.png",
          imgChildren: "https://h5.ophyer.cn/official_website/other/WanqingClou-5-lizi.gif",
          childrenTitle: '轻松制作多种动画',
          childrenTip: "序列帧动画编辑工具，轻松制作序列帧动画\n内置粒子工具实现粒子动画效果，让动画效果更具震撼力"
        },
        {
          tabTitle: '脚本自定义',
          img: "https://h5.ophyer.cn/official_website/other/develop5-3.png",
          childrenTitle: '支持预设API，控场随心所欲',
          childrenTip: "通过预设API控制场景元素运动及动画的播放，让场景的变现更随心"
        },
      ],
      bannerList: [
        {
          img: "https://h5.ophyer.cn/official_website/banner/3DSceneEditor-banner.png",
          title: ["万目云·3D场景编辑器"],
          titleColor:"#FFFFFF",
          tip: ["轻量易用的跨平台3D编辑工具，少量代码即可在线编辑出酷炫的3D效果"],
        },
      ],
      caseList: [
        textData.anli.feitianyundong,
        textData.anli.gaoerfu,
        textData.anli.kidsyundian,
        textData.anli.qicezhuangpei,
      ],
      showDialog: false
    }
  },
  created() {
  },
  methods: {
    handleBannerBtn(router) {
      if(router){
        window.open(window.location.protocol + "//" + window.location.host + router);
      }else {
        this.showDialog = true;
      }
    },
    goCaseDetail:(item) =>{
      let query = {
        source: 'qita',
        anli: item.self
      }
      let url = '/CaseDetail?'+qs.stringify(query)
      window.open(url, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog{
  z-index: 3;
  width: 550px;
  height: 380px;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background-color: #FFFFFF;
  box-shadow: 0px 0px 15px 5px rgb(0 114 255 / 10%);
  .close-btn{
    .icon {
      cursor: pointer;
      top: 10px;
      left: 480px;
    }
  }
  ::v-deep .v-card--link {
    cursor: unset;
  }
}
::v-deep .home-case {
  .new-tabs-items-two {
    width: 1200px;
    min-height: unset;
    padding-bottom: 70px;
    .v-window__container {
      background: #FFFFFF;
      padding-bottom: 0;
      min-height: unset;
    }
    .v-window-item {
      width: 100%;
      margin: 0;
      display: flex;
      justify-content: space-around;
      .item-card {
        display: inline-block;
      }
    }
  }
}

.gifStyle {
  position: absolute;
  width: 190px;
  height: 125px;
  top: calc(50% - 41px);
  left: calc(50% - 1px);
  transform: translate(-50%, -50%);

  img {
    width: 100%;
    height: 100%;
  }
}
</style>
